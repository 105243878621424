import { render, staticRenderFns } from "./PopupBox.vue?vue&type=template&id=4316a7be&scoped=true&"
import script from "./PopupBox.vue?vue&type=script&lang=js&"
export * from "./PopupBox.vue?vue&type=script&lang=js&"
import style0 from "./PopupBox.vue?vue&type=style&index=0&id=4316a7be&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../var/task/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4316a7be",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/task/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4316a7be')) {
      api.createRecord('4316a7be', component.options)
    } else {
      api.reload('4316a7be', component.options)
    }
    module.hot.accept("./PopupBox.vue?vue&type=template&id=4316a7be&scoped=true&", function () {
      api.rerender('4316a7be', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "tmp/tmp-8W5b4MUwzMA51/JS/comp/PopupBox.vue"
export default component.exports