<template>
    <div>
        <div v-if="accept"
            class="video-container">
            <div class="text-end"
                v-if="check4Cookie()">
                <button @click="deleteCookie()"
                    class="btn btn-default btn-sm">
                    {{voMsg('tpl.video.revokeConsent')}}
                </button>
            </div>
            <iframe class="video"
                width="100%"
                height="700"
                :src="getUrl()"
                frameborder="0"
                allowfullscreen
                allow="vr"></iframe>

        </div>
        <div v-else
            class="video-hint">
            <div class="text">
                {{voMsg('tpl.iframe.warning')}}
                <div v-html="voMsg('tpl.video.youtube')"
                    v-if="type === 'YouTube'"></div>
                <div v-html="voMsg('tpl.video.vimeo')"
                    v-if="type === 'Vimeo'"></div>
                <div v-html="voMsg('tpl.video.GoogleMaps')"
                    v-if="type === 'GoogleMaps'"></div>
                <div v-html="voMsg('tpl.video.Instagram')"
                    v-if="type === 'Instagram'"></div>
                <div v-html="voMsg('tpl.video.Matterport')"
                    v-if="type === 'Matterport'"></div>
                <div v-html="voMsg('tpl.video.Matterport')"
                    v-if="type === 'Panorama'"></div>
            </div>
            <div class="button-line">
                <button @click="showContent"
                    class="btn btn-default btn-sm">
                    {{voMsg('tpl.video.showOnce')}}
                </button>
                <button @click="allowContent"
                    class="btn btn-primary btn-sm">
                    {{voMsg('tpl.video.alwaysShow')}}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import V from 'voUtils/V.js'

    export default {
    	voVueComponent: 'video-iframe',
    	data() {
    		return {
    			accept: false
    		}
    	},
    	props: {
    		video: String,
    		type: String
    	},
    	mounted: function() {
    		//console.log('Type::', this.type);
    		const suffix = VOFFICE.token.substr(VOFFICE.token.length - 12);
    		var cookieName;
    		if (this.type === "YouTube") {
    			cookieName = 'allowYouTube_' + suffix;
    			if (this.getCookie(cookieName) === 'true') {
    				this.accept = true;
    			}
    		} else if (this.type === "Vimeo") {
    			cookieName = 'allowVimeo_' + suffix;
    			if (this.getCookie(cookieName) === 'true') {
    				this.accept = true;
    			}
    		} else if (this.type === "GoogleMaps") {
    			cookieName = 'allowGoogleMaps_' + suffix;
    			if (this.getCookie(cookieName) === 'true') {
    				this.accept = true;
    			}
    		} else if (this.type === "Instagram") {
    			cookieName = 'allowInstagram_' + suffix;
    			if (this.getCookie(cookieName) === 'true') {
    				this.accept = true;
    			}
    		} else if (this.type === "Matterport") {
    			cookieName = 'allowMatterport_' + suffix;
    			if (this.getCookie(cookieName) === 'true') {
    				this.accept = true;
    			}
    		} else if (this.type === "Panorama") {
    			cookieName = 'allowPanorama_' + suffix;
    			if (this.getCookie(cookieName) === 'true') {
    				this.accept = true;
    			}
    		}
    	},
    	methods: {
    		check4Cookie: function() {
    			const suffix = VOFFICE.token.substr(VOFFICE.token.length - 12);
    			var cookieName;
    			if (this.type === 'YouTube') {
    				cookieName = 'allowYouTube_' + suffix;
    				if (this.getCookie(cookieName) === 'true') {
    					return true;
    				}
    			} else if (this.type === 'Vimeo') {
    				cookieName = 'allowVimeo_' + suffix;
    				if (this.getCookie(cookieName) === 'true') {
    					return true;
    				}
    			} else if (this.type === 'Instagram') {
    				cookieName = 'allowInstagram_' + suffix;
    				if (this.getCookie(cookieName) === 'true') {
    					return true;
    				}
    			} else if (this.type === 'GoogleMaps') {
    				cookieName = 'allowGoogleMaps_' + suffix;
    				if (this.getCookie(cookieName) === 'true') {
    					return true;
    				}
    			} else if (this.type === 'Matterport') {
    				cookieName = 'allowMatterport_' + suffix;
    				if (this.getCookie(cookieName) === 'true') {
    					return true;
    				}
    			} else if (this.type === 'Panorama') {
    				cookieName = 'allowPanorama_' + suffix;
    				if (this.getCookie(cookieName) === 'true') {
    					return true;
    				}
    			}
    		},
    		deleteCookie: function() {
    			const suffix = VOFFICE.token.substr(VOFFICE.token.length - 12);
    			var cookieName;
    			if (this.type === 'YouTube') {
    				cookieName = 'allowYouTube_' + suffix;
    				this.accept = false;
    			} else if (this.type === 'Vimeo') {
    				cookieName = 'allowVimeo_' + suffix;
    				this.accept = false;
    			} else if (this.type === 'GoogleMaps') {
    				cookieName = 'allowGoogleMaps_' + suffix;
    				this.accept = false;
    			} else if (this.type === 'Instagram') {
    				cookieName = 'allowInstagram_' + suffix;
    				this.accept = false;
    			} else if (this.type === 'Matterport') {
    				cookieName = 'allowMatterport_' + suffix;
    				this.accept = false;
    			} else if (this.type === 'Panorama') {
    				cookieName = 'allowPanorama_' + suffix;
    				this.accept = false;
    			}

    			if (cookieName) {
    				document.cookie = cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    			}
    		},

    		getCookie: function(name) {
    			const cookieName = name + "=";
    			const decodedCookie = decodeURIComponent(document.cookie);
    			const cookieArray = decodedCookie.split(';');

    			for (let i = 0; i < cookieArray.length; i++) {
    				let cookie = cookieArray[i].trim();

    				if (cookie.indexOf(cookieName) === 0) {
    					return cookie.substring(cookieName.length, cookie.length);
    				}
    			}

    			return null; // Cookie wurde nicht gefunden
    		},

    		setCookie: function(name, value, days) {
    			const date = new Date();
    			date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000)); // Berechnung des Ablaufdatums in Millisekunden

    			const expires = "expires=" + date.toUTCString();
    			document.cookie = name + "=" + value + "; " + expires + "; path=/";
    		},
    		showContent: function() {
    			this.accept = true;
    		},
    		allowContent: function() {
    			const suffix = VOFFICE.token.substr(VOFFICE.token.length - 12);
    			var cookieName;
    			switch (this.type) {
    				case "YouTube":
    					cookieName = 'allowYouTube_' + suffix;
    					this.setCookie(cookieName, "true", 30);
    					this.accept = true;
    					break;
    				case "Vimeo":
    					cookieName = 'allowVimeo_' + suffix;
    					this.setCookie(cookieName, "true", 30);
    					this.accept = true;
    					break;
    				case "Instagram":
    					cookieName = 'allowInstagram_' + suffix;
    					this.setCookie(cookieName, "true", 30);
    					this.accept = true;
    					break;
    				case "GoogleMaps":
    					cookieName = 'allowGoogleMaps_' + suffix;
    					this.setCookie(cookieName, "true", 30);
    					this.accept = true;
    					break;
    				case "Matterport":
    					cookieName = 'allowMatterport_' + suffix;
    					this.setCookie(cookieName, "true", 30);
    					this.accept = true;
    					break;
    				case "Panorama":
    					cookieName = 'allowPanorama_' + suffix;
    					this.setCookie(cookieName, "true", 30);
    					this.accept = true;
    					break;
    			}
    		},

    		getUrl: function() {
    			var url = '';
    			if (this.type === 'YouTube' && this.accept) {
    				url = 'https://www.youtube-nocookie.com/embed/';
    				url += this.video;
    				url += '?rel=0';
    			} else if (this.type === 'Vimeo' && this.accept) {
    				url = 'https://player.vimeo.com/video/';
    				url += this.video;
    			} else if (this.type === 'Matterport' && this.accept) {
    				url = 'https://my.matterport.com/show/?m=';
    				url += this.video;
    			} else if (this.type == 'Panorama' && this.accept) {
    				url = 'https://3dblickwinkel.de/3d-rundgang/';
    				url += this.video;
    				url += '/fullscreen/?embedded'
    			}
    			return url;
    		}
    	}
    };
</script>