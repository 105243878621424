import {
	isBlank
}
from 'voUtils/tools.js';

import {
	favCount, onFavUpdate
}
from 'voUtils/FavStore.js';

export default {

	data: {
		name: undefined,
	},
	computed: {
		mailto: function () {
			return `mailto:${this.emailAddress}`;
		},
		emailAddress: function () {
			return (VOFFICE.contactEmail || ['info', 'test.de']).join('@');
		}

	},

	mounted: function () {
		console.log("event object is==>" +
			$('li.blockClick'));
		$('li.blockClick').on('click', function (event) {
			// The event won't be propagated up to the document NODE and 
			// therefore delegated events won't be fired
			console.log("inside event");
			event.stopPropagation();
		});
		onFavUpdate(() => {
			this.$forceUpdate();
		});

	},

	methods: {

		navbarToggle: function () {

			$('.main-menu').slideToggle();
			$('.navbar-v1').removeClass('open-by-mob');

			$(".submenu-arrow").click(function () {
				$(this).parent(".dropdown").toggleClass("opneSubmenu");
			});

			if ($(window).width() < 1200) {


			}
			/*if ($(window).width() < 1200) {
				$('.dropdown-toggle').on('click', function (event) {
					event.stopPropagation();
				});
			}*/


		},

		favCount: favCount,
		goSearch: function () {
			if (isBlank(this.name)) {
				window.location.href = 's';
			} else {
				window.location.href = 's#?name=' + encodeURIComponent(this.name);
			}
		}



	}
};