<template>
    <div>
        <div :id="getId"
            style="height: 100%; width: 100%; z-index:1;min-height: 250px;">
            <div ref="slot">
                <slot></slot>
            </div>
        </div>
    </div>
</template>
<script>
    import V from 'voUtils/V.js';
    import {
    	debounce
    }
    from 'voUtils/tools.js';

    export default {
    	voVueComponent: 'voffice-openstreetmap',
    	props: {
    		items: {
    			type: Array,
    			default: function() {
    				return [];
    			}
    		},
    		markerPos: {
    			type: Array,
    			default: function() {
    				return [];
    			}
    		},
    		mapId: {
    			type: String,
    			default: 'map'
    		},
    		highlight: Number,
    		initialPos: {
    			type: Array,
    			default: function() {
    				return [10, 54];
    			}
    		},
    		initialZoom: {
    			type: [String, Number],
    			default: 14
    		},
    		cluster: undefined,
    		selected: Object,
    		draggable: {
    			type: [String, Boolean],
    			default: true // true, false, center
    		},
    		scrollWheelZoom: {
    			type: [String, Boolean],
    			default: true // true, false, center
    		},
    		doubleClickZoom: {
    			type: [String, Boolean],
    			default: true // true, false, center
    		},
    	},
    	created: function() {
    		var vc = this;

    		/*this.onMarkerClicked = function() {
    			vc.$emit('update:selected', this.customData);
    		};*/
    	},
    	mounted: function() {
    		this.createMap();
    	},
    	methods: {
    		createMap() {

    				const tiles = window.L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    					attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Points &copy 2012 LINZ'
    				});

    				this.icon = window.L.divIcon({
    					html: `<?xml version="1.0" encoding="UTF-8"?><svg xmlns="http://www.w3.org/2000/svg" height="144px" width="144px" viewBox="0 0 359.14 154.63"><defs><style>.cls-1{fill:#5a927a;}</style></defs><path class="cls-1" d="M170.79,47.39c-1.46,0-2.75,.71-3.55,1.8-.8-1.09-2.09-1.8-3.55-1.8-2.44,0-4.42,1.98-4.42,4.42s1.98,4.42,4.42,4.42c1.46,0,2.75-.71,3.55-1.8,.8,1.09,2.09,1.8,3.55,1.8,2.44,0,4.42-1.98,4.42-4.42s-1.98-4.42-4.42-4.42Z"/><path class="cls-1" d="M196.58,24.14c-6.45-6.92-15.94-12.61-29.69-12.61-16.28,0-26.8,9.58-33.2,18.98-4.74,6.96-6.81,15.31-6.16,23.58,.3,3.83,1.4,7.55,3.01,11.04l5.69,12.3v-23.71c0-.63,.26-1.23,.7-1.67l29.52-28.96c.47-.46,1.11-.74,1.77-.66,.54,0,1.14,.28,1.57,.74l26.73,28.97c.4,.44,.62,1,.62,1.58v12.12c0,1.28-1.05,2.33-2.33,2.33s-2.33-1.05-2.33-2.33v-11.21l-24.48-26.53-27.11,26.6v32.8l24.29,52.5,37.67-73.34c1.57-3.06,2.82-6.3,3.35-9.69,1.86-11.78-1.4-23.99-9.63-32.81Zm-26.17,34.88s0,.04,0,.05v1.75c0,.48-.39,.87-.87,.87h-.05v24.3c.41,.02,.73,.36,.73,.77v1.37c0,.41-.33,.75-.73,.77v16.55c0,.89-.72,1.61-1.61,1.61h-1.3c-.89,0-1.61-.72-1.61-1.61v-3.31h-1.24v1.72h-5.24v-3.04h2.4v-1.69h-2.4v-3.23h2.4v-1.69h-2.4v-3.04h5.24v1.72h1.24v-3.99c-.41-.02-.73-.36-.73-.77v-1.37c0-.41,.33-.75,.73-.77v-24.3h-.05c-.48,0-.87-.39-.87-.87v-1.75s0-.04,0-.05c-4.29-.96-7.41-3.83-7.41-7.21,0-3.82,3.97-6.98,9.12-7.48,.25-.75,.81-1.28,1.46-1.28s1.21,.52,1.46,1.28c5.15,.51,9.12,3.66,9.12,7.48,0,3.38-3.11,6.25-7.41,7.21Z"/></svg>`,
    					className: "openstreet-marker",
    					iconSize: [144, 144],
    					popupAnchor: [-5, -30]
    				})

    				const map = window.L.map(this.getId, {
    					center: new window.L.LatLng(this.composeGeolocation(this.initialPos[1]), this.composeGeolocation(this.initialPos[0]), {
    						icon: this.icon
    					}),
    					fullscreenControl: true,
    					zoom: this.initialZoom,
    					dragging: this.draggable,
    					doubleClickZoom: this.doubleClickZoom,
    					scrollWheelZoom: this.scrollWheelZoom,
    					attributionControl: false,
    					layers: [tiles]
    				});
    				map.on('popupopen', function(e) {
    					$('.leaflet-popup-close-button').removeAttr('href');
    					var px = map.project(e.target._popup._latlng);
    					px.y -= e.target._popup._container.clientHeight / 2 + 100;
    					map.panTo(map.unproject(px), {
    						animate: false
    					});
    				});
    				this.markers = [];
    				this.map = map;
    				if (this.markerPos && this.markerPos.length > 0) {
    					this.updateSingleMarker();
    				}
    			},
    			updateSingleMarker() {
    				if (this.map) {
    					this.deleteMarkers();

    					const marker = new window.L.marker(new window.L.LatLng(this.composeGeolocation(this.markerPos[1]), this.composeGeolocation(this.markerPos[0])), {
    						icon: this.icon
    					});
    					marker.addTo(this.map);
    					this.map.panTo(new window.L.LatLng(this.composeGeolocation(this.markerPos[1]), this.composeGeolocation(this.markerPos[0])));
    				}

    			},
    			deleteMarkers() {
    				if (this.markers) {
    					this.map.removeLayer(this.markers);
    				}
    				if (this.clusterObject) {
    					this.map.removeLayer(this.clusterObject);
    				}
    				this.markers = [];
    			},
    			updateMarkers() {
    				if (this.map) {
    					this.deleteMarkers();
    					var clusters = window.L.markerClusterGroup({
    						animate: false
    					});
    					var markers = [];

    					if (this.items.length > 0) {
    						for (var i = 0; i < this.items.length; i++) {
    							var unit = this.items[i];

    							if (!unit) {
    								continue;
    							}

    							let marker;
    							if (unit.loc) {
    								marker = new window.L.marker(new window.L.LatLng(this.composeGeolocation(unit.loc.coordinates[1]), this.composeGeolocation(unit.loc.coordinates[0])), {
    									icon: this.icon,
    									customData: {
    										info: unit
    									}
    								}).setBouncingOptions({
    									bounceHeight: 30,
    									bounceSpeed: 54,
    									exclusive: true,
    								}).on('click', () => {
    									this.$emit('update:selected', marker.options.customData.info);
    								}).bindPopup(this.$refs.slot, {
    									minWidth: 320,
    									autopan: true
    								});

    								if (i === 0) {
    									this.map.flyTo(marker.getLatLng());
    								}
    								if (this.cluster) {
    									clusters.addLayer(marker);
    								} else {
    									marker.addTo(this.map)
    								}
    								this.markers.push(marker);
    								marker.getPopup().on(('remove'), () => {
    									this.$emit('update:selected', {});
    								});
    							} else if (Array.isArray(unit)) {
    								for (let i = 2; i < unit.length; i++) {
    									marker = new window.L.marker(new window.L.LatLng(this.composeGeolocation(unit[1]), this.composeGeolocation(unit[0])), {
    										icon: this.icon,
    										customData: {
    											_id: unit[i],
    											lazy: true,
    											info: unit
    										}
    									}).setBouncingOptions({
    										bounceHeight: 30,
    										bounceSpeed: 54,
    										exclusive: true,
    									}).on('click', () => {
    										this.$emit('update:selected', marker.options.customData);
    									}).bindPopup(this.$refs.slot, {
    										minWidth: 320,
    										autopan: true
    									});
    									marker.getPopup().on(('remove'), () => {
    										this.$emit('update:selected', {});
    									});
    									this.markers.push(marker);
    									clusters.addLayer(marker);
    								}
    							}
    						}
    					}


    					if (this.cluster) {
    						this.clusterObject = clusters;
    						this.map.addLayer(clusters)
    					}
    					this.map.invalidateSize(true);
    				}
    			},
    			composeGeolocation(input) {
    				return input.toFixed(6) + Math.floor(Math.random() * 4);
    			},
    			unHighlightMarker(m) {
    				if (this.highlightedMarker) {
    					if (this.highlightedMarker._icon) {
    						this.highlightedMarker._icon.classList.remove("animate-marker");
    					}
    					this.highlightedMarker = undefined;
    				}
    			},
    			highlightMarker: function(m) {
    				this.highlightedMarker = m;
    				this.map.once('moveend zoomend', () => {
    					const parent = this.clusterObject.getVisibleParent(m);
    					if (parent && parent.spiderfy) {
    						parent.spiderfy();
    					}
    					if ((this.highlightedMarker === m || !this.highlightedMarker) && m._icon) {
    						m._icon.classList.add("animate-marker");
    						m.bounce(2);
    					}
    					if (!this.highlightedMarker === m) {
    						this.unHighlightMarker(this.highlightedMarker);
    					}
    				});
    			},

    			findMarkerById(id) {
    				if (this.markers) {
    					for (let m of this.markers) {
    						if (m.options && m.options.customData && ((m.options.customData.info && m.options.customData.info._id == id) || (m.options.customData._id == id))) {
    							return m;
    						}
    					}
    				}
    			}
    	},
    	watch: {
    		items: {
    			deep: true,
    			handler: function(nv) {
    				this.updateMarkers();
    			}
    		},
    		highlight: function(id) {
    			this.unHighlightMarker(this.highlightedMarker);

    			if (id) {
    				let m = this.findMarkerById(id);

    				if (m) {
    					if (!m.getPopup().isOpen()) {
    						this.map.closePopup();
    					}
    					this.map.flyTo(m.getLatLng(), Math.max(this.map.getZoom(), 13), {
    						animate: true,
    						duration: 0.4
    					});
    					this.highlightMarker(m);

    				}
    			}
    		},
    		markerPos: {
    			deep: true,
    			handler: function(val, oldVal) {
    				this.updateSingleMarker();
    			}
    		},
    		selected: function(nv) {
    			this.unHighlightMarker(this.highlightedMarker);
    			if (nv._id) {
    				var m = this.findMarkerById(nv._id);
    				if (m) {
    					m.openPopup();
    				} else {
    					this.map.closePopup();
    				}
    			} else {
    				this.map.closePopup();
    			}
    		}

    	},
    	computed: {
    		getId: function() {
    			return this.mapId;
    		}
    	}
    };
</script>